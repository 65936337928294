import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

// FORM VALIDATION
import Bouncer from './lib/bouncer.polyfills';

const contactForm = document.querySelector('#contactForm');

if (typeof (contactForm) != 'undefined' && contactForm != null) {
  let bouncer = new Bouncer('#contactForm', {
    fieldClass: 'uk-form-danger',
    disableSubmit: true,
    messages: {
      missingValue: {
        default: contactForm.dataset.defaultErrorMsg
      }
    }
  });
}

document.addEventListener('bouncerFormInvalid', function (event) {
  window.scrollTo(0, (event.detail.errors[0].offsetTop - 120));
}, false);

document.addEventListener('bouncerFormValid', function (event) {
  // The successfully validated form
  let form = event.target;
  let oData = new FormData(form);

  let oReq = new XMLHttpRequest();
  oReq.open('POST', 'mail.php', true);

  oReq.onload = function(oEvent) {
    if (oReq.status == 200) {
      console.log('uploaded');
    } else {
      console.log("Error " + oReq.status + " occurred when trying to upload your file.<br \/>");
    }
  };

  oReq.send(oData);
  event.preventDefault();
}, false);
// end of FORM VALIDATION

UIkit.use(Icons);

let WebFont = require('webfontloader');

// FONTS LOADER
WebFont.load({
  google: {
    // families: ['IBM Plex Sans:300,300i,400,400i,600,600i,700,700i']
    families: ['Oswald:700', 'Roboto:400,700']
  }
});
// end of FONTS LOADER

// GALLERY ACTIONS
let galleryActions = document.getElementById('galleryActions');

if (galleryActions) {
  UIkit.util.on(document, 'shown', '.uk-lightbox.uk-open', function () {
    galleryActions.classList.remove('uk-hidden');
    galleryActions.classList.add('uk-animation-fade');
  });

  UIkit.util.on(document, 'hidden', '.uk-lightbox', function () {
    galleryActions.classList.add('uk-hidden');
  });
}
// end of GALLERY ACTIONS

// SCROLL AFTER FILTER
let anchor = document.querySelector('#galleryFilterColumn'),
    behavior = UIkit.scroll('[data-uk-filter]', {
      offset: 60
    });

document.addEventListener('click', function () {
  UIkit.util.on('[data-uk-filter]', 'afterFilter', function () {
    behavior.scrollTo('#galleryFilterColumn');
  });
});
// end of SCROLL AFTER FILTER

// HIGHLIGHT MODEL ZONES
// document.addEventListener('click', function (event) {
//   let clickedElem = event.target;
//
//   if (clickedElem.classList.contains('is-highlighted')) return;
//
// }, false);
// end of HIGHLIGHT MODEL ZONES
